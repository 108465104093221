import React, { useEffect, useState } from 'react'
import { navigate } from 'gatsby'
import styled from 'styled-components'
// Components
import { Loading, PageHeader, Seo } from '../components'
import { DeleteAutoshipModal, AutoShipTab } from '../components/Autoship'
// Contexts
import { useAuthContext } from '../context/AuthContext'
import { useCartContext } from '../context/CartContext'
import { usePrismic } from '../context/PrismicContext'
import { useStoreContext } from '../context/StoreContext'
// Styles
// Utils
import { formatDate } from '../utils/dateHelpers'
// Services

const SubscriptionsPage = () => {
  const { userType, isEventSite, isVipSite, getSmartshipExpiration } =
    useAuthContext()
  const { isStoreLoading } = useStoreContext()
  const {
    autoShipData,
    autoShipDate,
    isPcOfferInCart,
    isAmbOfferInCart,
    handleSetAutoShipState,
  } = useCartContext()

  const {
    prismicData: {
      prismicAutoshipPage: { loading, manage_autoship },
    },
  } = usePrismic()

  const showAutoShip =
    !(isVipSite || isEventSite) &&
    (isPcOfferInCart || isAmbOfferInCart || userType !== 'RETAIL')

  useEffect(() => {
    getSmartshipExpiration()
    if (!showAutoShip) {
      navigate('/')
    }
  }, [])

  const onClickClose = () => {
    if (autoShipDate || !autoShipData) navigate(-1)
    else {
      handleSetAutoShipState({
        autoShipDate: formatDate(autoShipData.dateNextProcess),
      })
    }
  }

  if (isStoreLoading)
    return <Loading loading={isStoreLoading} message={loading[0].text} />

  return (
    <>
      <Seo title="Subscriptions" />
      <PageHeader onClickClose={onClickClose}>
        {manage_autoship[0].text}s
      </PageHeader>
      <AutoShipTab></AutoShipTab>
    </>
  )
}

export default SubscriptionsPage
